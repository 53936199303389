import React from "react"
import { Container, Row, Col } from "reactstrap"
import { Controller } from "react-hook-form"
import DatePicker from "react-datepicker"
const modeOfTraining = [
  "Classroom",
  "Asynchronous eLearning",
  "In-house",
  "On-the-Job",
  "Practical / Practicum",
  "Supervised Field",
  "Traineeship",
  "Assessment",
  "Synchronous eLearning",
]
const courseVacancy = [
  {
    value: "A",
    label: "Available",
  },
  {
    value: "F",
    label: "Full",
  },
  {
    value: "L",
    label: "Limited Vacancy",
  },
]
const trainerType = ["Existing", "New"]
const startDate = new Date()
const CourseRun = ({ register, errors, control }) => {
  return (
    <Container>
      <Row>
        <Col xs="2">
          <label class="col-form-label">General</label>
        </Col>
        <Col>
          <Row className="py-2">
            <Col xs="6">
              <div class="mb-3">
                <label class="form-label">
                  Course Title
                </label>
                <input
                  {...register("title", {
                    required: true,
                  })}
                  class="form-control"
                />
                {errors.title && (
                  <span class="error-message">
                    This field is required
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <Row className="py-2">
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">Start Date</label>
                <Controller
                  name="startDate"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <DatePicker
                      onChange={(date) =>
                        field.onChange(date)
                      }
                      selected={field.value}
                    />
                  )}
                />
                {errors.startDate && (
                  <span class="error-message">
                    This field is required
                  </span>
                )}
              </div>
            </Col>
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">End Date</label>
                <Controller
                  name="endDate"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <DatePicker
                      onChange={(date) =>
                        field.onChange(date)
                      }
                      selected={field.value}
                    />
                  )}
                />
                {errors.endDate && (
                  <span class="error-message">
                    This field is required
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <Row className="py-2">
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">
                  Opening Registration Dates
                </label>
                <Controller
                  name="openingRegistrationDate"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <DatePicker
                      onChange={(date) =>
                        field.onChange(date)
                      }
                      selected={field.value}
                    />
                  )}
                />
                {errors.openingRegistrationDate && (
                  <span class="error-message">
                    This field is required
                  </span>
                )}
              </div>
            </Col>
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">
                  Closing Registration Dates
                </label>
                <Controller
                  name="closingRegistrationDate"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <DatePicker
                      onChange={(date) =>
                        field.onChange(date)
                      }
                      selected={field.value}
                    />
                  )}
                />
                {errors.closingRegistrationDate && (
                  <span class="error-message">
                    This field is required
                  </span>
                )}
              </div>
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs="6">
              <div class="mb-3">
                <label class="form-label">
                  Schedule Info
                </label>
                <input
                  {...register("scheduleInfo", {
                    required: true,
                  })}
                  class="form-control"
                />
                {errors.scheduleInfo && (
                  <span class="error-message">
                    This field is required
                  </span>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">
                  Course Admin Email
                </label>
                <input
                  {...register("courseAdminEmail", {
                    required: true,
                    pattern:
                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  })}
                  class="form-control"
                />
                {errors.courseAdminEmail?.type ===
                  "required" && (
                  <span class="error-message">
                    This field is required
                  </span>
                )}
                {errors.courseAdminEmail?.type ===
                  "pattern" && (
                  <span class="error-message">
                    Invalid email address
                  </span>
                )}
              </div>
            </Col>
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">
                  Mode of Training
                </label>
                <select
                  {...register("modeOfTraining", {
                    required: true,
                  })}
                  class="form-control"
                >
                  {modeOfTraining.map((value, index) => (
                    <option value={index + 1}>
                      {value}
                    </option>
                  ))}
                </select>
                {errors.modeOfTraining && (
                  <span class="error-message">
                    This field is required
                  </span>
                )}
              </div>
            </Col>
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">
                  Course Vacancy
                </label>
                <select
                  {...register("courseVacancy", {
                    required: true,
                  })}
                  class="form-control"
                >
                  {courseVacancy.map((item) => (
                    <option value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
                {errors.courseVacancy && (
                  <span class="error-message">
                    This field is required
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs="2">
          <label class="col-form-label">Venue</label>
        </Col>
        <Col>
          <Row className="py-2">
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">Street</label>
                <input
                  {...register("venueStreet")}
                  class="form-control"
                />
              </div>
            </Col>
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">building</label>
                <input
                  {...register("venueBuilding")}
                  class="form-control"
                />
              </div>
            </Col>
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">floor</label>
                <input
                  {...register("venueFloor", {
                    required: true,
                  })}
                  class="form-control"
                />
                {errors.venueFloor && (
                  <span class="error-message">
                    This field is required
                  </span>
                )}
              </div>
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">block</label>
                <input
                  {...register("venueBlock")}
                  class="form-control"
                />
              </div>
            </Col>
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">unit</label>
                <input
                  {...register("venueUnit", {
                    required: true,
                  })}
                  class="form-control"
                />
                {errors.venueUnit && (
                  <span class="error-message">
                    This field is required
                  </span>
                )}
              </div>
            </Col>
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">room</label>
                <input
                  {...register("venueRoom", {
                    required: true,
                  })}
                  class="form-control"
                />
                {errors.venueRoom && (
                  <span class="error-message">
                    This field is required
                  </span>
                )}
              </div>
            </Col>
          </Row>
          <Row className="py-2">
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">
                  postal code
                </label>
                <input
                  {...register("venuePostalCode", {
                    required: true,
                  })}
                  class="form-control"
                />
                {errors.venuePostalCode && (
                  <span class="error-message">
                    This field is required
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs="2">
          <label class="col-form-label">Trainer</label>
        </Col>
        <Col>
          <Row className="py-2">
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">name</label>
                <input
                  {...register("trainerName", {
                    required: true,
                  })}
                  class="form-control"
                />
                {errors.trainerName && (
                  <span class="error-message">
                    This field is required
                  </span>
                )}
              </div>
            </Col>
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">email</label>
                <input
                  {...register("trainerEmail", {
                    required: true,
                    pattern:
                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  })}
                  class="form-control"
                />
                {errors.trainerEmail?.type ===
                  "required" && (
                  <span class="error-message">
                    This field is required
                  </span>
                )}
                {errors.trainerEmail?.type ===
                  "pattern" && (
                  <span class="error-message">
                    Invalid email address
                  </span>
                )}
              </div>
            </Col>
            <Col xs="4">
              <div class="mb-3">
                <label class="form-label">
                  Trainer Type
                </label>
                <select
                  {...register("trainerType", {
                    required: true,
                  })}
                  class="form-control"
                >
                  {trainerType.map((value, index) => (
                    <option value={index + 1}>
                      {value}
                    </option>
                  ))}
                </select>
                {errors.trainerType && (
                  <span class="error-message">
                    This field is required
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default CourseRun
