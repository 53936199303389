import React from "react"
import { Container } from "reactstrap"
import styled from "styled-components"
import { useForm } from "react-hook-form"
import { withAuthenticator } from "@aws-amplify/ui-react"
import { isAuthenticatedUserMemberOfAdmin } from "../../../utils/auth"
import { onAuthUIStateChange } from "@aws-amplify/ui-components"
import { createCourseRun } from "../../../graphql/mutations"
import { API } from "aws-amplify"
import CourseRun from "../../../components/forms/courseRun"
import CourseSession from "../../../components/forms/courseSession"
import { BsPlusCircle } from "react-icons/bs"
import Button from "../../../components/buttons/btn"
let StyledButton = styled(Button)`
  display: block;
  margin: 1em 0 0.4em 0;
`
let Form = styled.form`
  input,
  select {
    padding: 0.4em;
    margin: 0 1em 0 0;
  }
  .error-message {
    display: block;
    color: #bf1650;
  }
  .react-datepicker-wrapper {
    display: block;
  }
  .add-sessions {
    height: 30px;
    width: 30px;
  }
  .session-buttons {
    display: flex;
    align-items: center;
    margin-top: 2rem;
  }
`
const Runs = () => {
  const [indexes, setIndexes] = React.useState([])
  const [counter, setCounter] = React.useState(0)
  const [submitted, setSubmitted] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [user, setUser] = React.useState()
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm()
  React.useEffect(() => {
    return onAuthUIStateChange(
      (nextAuthState, authData) => {
        setUser(authData)
      }
    )
  }, [])
  const onSubmit = async (data) => {
    console.log(data)
    setSubmitting(true)
    let runID
    try {
      const result = await API.graphql({
        query: createCourseRun,
        variables: {
          input: {
            title: data.title,
            referenceNumber: "CR-111",
            startDate: data.startDate,
            endDate: data.endDate,
            scheduleInfo: data.scheduleInfo,
            modeOfTraining: data.modeOfTraining,
            courseVacancy: data.courseVacancy,
            openingRegistrationDate:
              data.openingRegistrationDate,
            closingRegistrationDate:
              data.closingRegistrationDate,
            courseAdminEmail: data.courseAdminEmail,
            venueStreet: data.venueStreet,
            venueBuilding: data.venueBuilding,
            venueFloor: data.venueFloor,
            venueBlock: data.venueBlock,
            venueUnit: data.venueUnit,
            venueRoom: data.venueRoom,
            venuePostalCode: data.venuePostalCode,
            trainers: [
              {
                name: data.trainerName,
                email: data.trainerEmail,
                trainerType: data.trainerType,
              },
            ],
            sessions: data.sessions.map((session) => {
              return {
                startDate: session.startDate,
                endDate: session.endDate,
                startTime: session.startTime,
                endTime: session.endTime,
                modeOfTraining: session.modeOfTraining,
                venueStreet: session.venueStreet,
                venueBuilding: session.venueBuilding,
                venueFloor: session.venueFloor,
                venueBlock: session.venueBlock,
                venueUnit: session.venueUnit,
                venueRoom: session.venueRoom,
                venuePostalCode: session.venuePostalCode,
              }
            }),
          },
        },
      })
      runID = result.data.createCourseRun.id
      if (!result.hasOwnProperty("errors")) {
        setSubmitted(true)
      }
    } catch (error) {
      console.log(error)
    }
    setSubmitting(false)
  }
  const addSession = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter])
    setCounter((prevCounter) => prevCounter + 1)
  }

  const removeSession = (index) => () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index),
    ])
    setCounter((prevCounter) => prevCounter - 1)
  }

  const clearSessions = () => {
    setIndexes([])
  }
  const isMemberOfAdmin = user
    ? isAuthenticatedUserMemberOfAdmin(user)
    : false
  return isMemberOfAdmin ? (
    <>
      {submitted ? (
        <Container className="pt-5">
          <h1>Course Run has been created!</h1>
        </Container>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Container className="pt-5">
            <h1>Create Course Run</h1>
            <CourseRun
              {...{
                register,
                errors,
                control,
              }}
            />
          </Container>

          <Container className="py-5">
            <h2>Add Session(s)</h2>
            <CourseSession
              {...{
                register,
                errors,
                control,
                indexes,
                counter,
                removeSession,
                clearSessions,
              }}
            />
            <div className="session-buttons">
              <div>
                <BsPlusCircle
                  className="add-sessions mr-4"
                  onClick={addSession}
                />
              </div>
              <div>
                <button
                  onClick={clearSessions}
                  type="button"
                >
                  Clear Sessions
                </button>
              </div>
            </div>
          </Container>
          <Container className="pb-5">
            <StyledButton
              type="submit"
              className={
                submitting
                  ? "w-25 is-loading button"
                  : "w-25 button"
              }
              disabled={submitting}
            >
              CREATE RUN
            </StyledButton>
          </Container>
        </Form>
      )}
    </>
  ) : (
    <>
      <h1 class="text-center pt-4">
        You are not allowed to access this page.
      </h1>
    </>
  )
}

export default withAuthenticator(Runs)
