/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      name
      email
      phone
      start_date
      start_time
      course
      createdAt
      updatedAt
    }
  }
`;
export const createCourseRun = /* GraphQL */ `
  mutation CreateCourseRun(
    $input: CreateCourseRunInput!
    $condition: ModelCourseRunConditionInput
  ) {
    createCourseRun(input: $input, condition: $condition) {
      id
      ssgRunID
      referenceNumber
      title
      startDate
      endDate
      openingRegistrationDate
      closingRegistrationDate
      scheduleInfo
      modeOfTraining
      courseVacancy
      courseAdminEmail
      venueStreet
      venueBuilding
      venueFloor
      venueBlock
      venueUnit
      venueRoom
      venuePostalCode
      sessions {
        ssgSessionID
        startDate
        endDate
        startTime
        endTime
        modeOfTraining
        venueStreet
        venueBuilding
        venueFloor
        venueBlock
        venueUnit
        venueRoom
        venuePostalCode
      }
      trainers {
        ssgTrainerID
        name
        email
        trainerType
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCourseRun = /* GraphQL */ `
  mutation UpdateCourseRun(
    $input: UpdateCourseRunInput!
    $condition: ModelCourseRunConditionInput
  ) {
    updateCourseRun(input: $input, condition: $condition) {
      id
      ssgRunID
      referenceNumber
      title
      startDate
      endDate
      openingRegistrationDate
      closingRegistrationDate
      scheduleInfo
      modeOfTraining
      courseVacancy
      courseAdminEmail
      venueStreet
      venueBuilding
      venueFloor
      venueBlock
      venueUnit
      venueRoom
      venuePostalCode
      sessions {
        ssgSessionID
        startDate
        endDate
        startTime
        endTime
        modeOfTraining
        venueStreet
        venueBuilding
        venueFloor
        venueBlock
        venueUnit
        venueRoom
        venuePostalCode
      }
      trainers {
        ssgTrainerID
        name
        email
        trainerType
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCourseRun = /* GraphQL */ `
  mutation DeleteCourseRun(
    $input: DeleteCourseRunInput!
    $condition: ModelCourseRunConditionInput
  ) {
    deleteCourseRun(input: $input, condition: $condition) {
      id
      ssgRunID
      referenceNumber
      title
      startDate
      endDate
      openingRegistrationDate
      closingRegistrationDate
      scheduleInfo
      modeOfTraining
      courseVacancy
      courseAdminEmail
      venueStreet
      venueBuilding
      venueFloor
      venueBlock
      venueUnit
      venueRoom
      venuePostalCode
      sessions {
        ssgSessionID
        startDate
        endDate
        startTime
        endTime
        modeOfTraining
        venueStreet
        venueBuilding
        venueFloor
        venueBlock
        venueUnit
        venueRoom
        venuePostalCode
      }
      trainers {
        ssgTrainerID
        name
        email
        trainerType
      }
      createdAt
      updatedAt
    }
  }
`;
