import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap"
import { Controller } from "react-hook-form"
import DatePicker from "react-datepicker"
const modeOfTraining = [
  "Classroom",
  "Asynchronous eLearning",
  "In-house",
  "On-the-Job",
  "Practical / Practicum",
  "Supervised Field",
  "Traineeship",
  "Assessment",
  "Synchronous eLearning",
]
const CourseSession = ({
  register,
  errors,
  control,
  indexes,
  counter,
  removeSession,
  clearSessions,
}) => {
  // console.log(watch("example")); // watch input value by passing the name of it

  return (
    <div>
      {indexes.map((index) => {
        const fieldName = `sessions[${index}]`
        return (
          <div class="mt-4">
            <Card>
              <CardHeader>New session</CardHeader>
              <CardBody>
                <Container name={fieldName} key={fieldName}>
                  <Row>
                    <Col xs="2">
                      <label class="col-form-label">
                        General
                      </label>
                    </Col>

                    <Col>
                      <Row className="py-2">
                        <Col xs="4">
                          <div class="mb-3">
                            <label class="form-label">
                              Start Date
                            </label>
                            <Controller
                              name={`${fieldName}.startDate`}
                              control={control}
                              defaultValue=""
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => (
                                <DatePicker
                                  onChange={(date) =>
                                    field.onChange(date)
                                  }
                                  selected={field.value}
                                />
                              )}
                            />
                            {errors.hasOwnProperty(
                              "sessions"
                            ) &&
                              errors.sessions[index]
                                .startDate && (
                                <span class="error-message">
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col xs="4">
                          <div class="mb-3">
                            <label class="form-label">
                              Start Time
                            </label>
                            <input
                              {...register(
                                `${fieldName}.startTime`,
                                {
                                  required: true,
                                  pattern:
                                    /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
                                }
                              )}
                              class="form-control"
                            />
                            {errors.hasOwnProperty(
                              "sessions"
                            ) &&
                              errors.sessions[index]
                                .startTime?.type ===
                                "required" && (
                                <span class="error-message">
                                  This field is required
                                </span>
                              )}
                            {errors.hasOwnProperty(
                              "sessions"
                            ) &&
                              errors.sessions[index]
                                .startTime?.type ===
                                "pattern" && (
                                <span class="error-message">
                                  Invalid time format
                                </span>
                              )}
                          </div>
                        </Col>
                      </Row>

                      <Row className="py-2">
                        <Col xs="4">
                          <div class="mb-3">
                            <label class="form-label">
                              End Date
                            </label>
                            <Controller
                              name={`${fieldName}.endDate`}
                              control={control}
                              defaultValue=""
                              rules={{
                                required: true,
                              }}
                              render={({ field }) => (
                                <DatePicker
                                  onChange={(date) =>
                                    field.onChange(date)
                                  }
                                  selected={field.value}
                                />
                              )}
                            />
                            {errors.hasOwnProperty(
                              "sessions"
                            ) &&
                              errors.sessions[index]
                                .endDate && (
                                <span class="error-message">
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                        <Col xs="4">
                          <div class="mb-3">
                            <label class="form-label">
                              End Time
                            </label>
                            <input
                              {...register(
                                `${fieldName}.endTime`,
                                {
                                  required: true,
                                  pattern:
                                    /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
                                }
                              )}
                              class="form-control"
                            />
                            {errors.hasOwnProperty(
                              "sessions"
                            ) &&
                              errors.sessions[index].endTime
                                ?.type === "required" && (
                                <span class="error-message">
                                  This field is required
                                </span>
                              )}
                            {errors.hasOwnProperty(
                              "sessions"
                            ) &&
                              errors.sessions[index].endTime
                                ?.type === "pattern" && (
                                <span class="error-message">
                                  Invalid time format
                                </span>
                              )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="4">
                          <div class="mb-3">
                            <label class="form-label">
                              Mode of Training
                            </label>
                            <select
                              {...register(
                                `${fieldName}.modeOfTraining`,
                                {
                                  required: true,
                                }
                              )}
                              class="form-control"
                            >
                              {modeOfTraining.map(
                                (value, index) => (
                                  <option value={index + 1}>
                                    {value}
                                  </option>
                                )
                              )}
                            </select>
                            {errors.hasOwnProperty(
                              "sessions"
                            ) &&
                              errors.sessions[index]
                                .modeOfTraining && (
                                <span class="error-message">
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="2">
                      <label class="col-form-label">
                        Venue
                      </label>
                    </Col>
                    <Col>
                      <Row className="py-2">
                        <Col xs="4">
                          <div class="mb-3">
                            <label class="form-label">
                              Street
                            </label>
                            <input
                              {...register(
                                `${fieldName}.venueStreet`
                              )}
                              class="form-control"
                            />
                          </div>
                        </Col>

                        <Col xs="4">
                          <div class="mb-3">
                            <label class="form-label">
                              building
                            </label>
                            <input
                              {...register(
                                `${fieldName}.venueBuilding`
                              )}
                              class="form-control"
                            />
                          </div>
                        </Col>

                        <Col xs="4">
                          <div class="mb-3">
                            <label class="form-label">
                              floor
                            </label>
                            <input
                              {...register(
                                `${fieldName}.venueFloor`,
                                {
                                  required: true,
                                }
                              )}
                              class="form-control"
                            />
                            {errors.hasOwnProperty(
                              "sessions"
                            ) &&
                              errors.sessions[index]
                                .venueFloor && (
                                <span class="error-message">
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                      </Row>

                      <Row className="py-2">
                        <Col xs="4">
                          <div class="mb-3">
                            <label class="form-label">
                              block
                            </label>
                            <input
                              {...register(
                                `${fieldName}.venueBlock`
                              )}
                              class="form-control"
                            />
                          </div>
                        </Col>

                        <Col xs="4">
                          <div class="mb-3">
                            <label class="form-label">
                              unit
                            </label>
                            <input
                              {...register(
                                `${fieldName}.venueUnit`,
                                {
                                  required: true,
                                }
                              )}
                              class="form-control"
                            />
                            {errors.hasOwnProperty(
                              "sessions"
                            ) &&
                              errors.sessions[index]
                                .venueUnit && (
                                <span class="error-message">
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>

                        <Col xs="4">
                          <div class="mb-3">
                            <label class="form-label">
                              room
                            </label>
                            <input
                              {...register(
                                `${fieldName}.venueRoom`,
                                {
                                  required: true,
                                }
                              )}
                              class="form-control"
                            />
                            {errors.hasOwnProperty(
                              "sessions"
                            ) &&
                              errors.sessions[index]
                                .venueRoom && (
                                <span class="error-message">
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="py-2">
                        <Col xs="4">
                          <div class="mb-3">
                            <label class="form-label">
                              postal code
                            </label>
                            <input
                              {...register(
                                `${fieldName}.venuePostalCode`,
                                {
                                  required: true,
                                }
                              )}
                              class="form-control"
                            />
                            {errors.hasOwnProperty(
                              "sessions"
                            ) &&
                              errors.sessions[index]
                                .venuePostalCode && (
                                <span class="error-message">
                                  This field is required
                                </span>
                              )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </CardBody>
            </Card>
          </div>
        )
      })}
    </div>
  )
}

export default CourseSession
